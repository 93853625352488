import { map } from "lodash";
import { Select } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useCallback } from "react";

import SelectComponents from "src/components/SelectComponents";
import usePagedTagSearch from "src/hooks/usePagedTagSearch";

import { SelectorContext } from "../TagsEditor/contexts/selectorContext";
import { MenuList } from "./components/MenuList";
import useSelectedTagValue from "./hooks/useSelectedTagValue";

const TagSearchSelect = ({
  tagTypes,
  value,
  onSelectTag,
  label,
  disabled,
  invalid,
  mandatory,
  focus,
}) => {
  const { selectedValue, setSelectedValue, selectedValueLoading } =
    useSelectedTagValue(value);

  const { tags, loading, loadOptions, loadMore, loadMoreLoading } =
    usePagedTagSearch({
      tagTypes,
    });

  const handleSelect = (selected) => {
    setSelectedValue(selected);
    onSelectTag(selected);
  };

  const options = map(tags, (tag) => ({
    label: tag.displayValue,
    value: tag.id,
  }));

  const handleInputChange = useCallback(
    (val, action) => {
      if (action.action !== "input-blur" && action.action !== "menu-close") {
        loadOptions(val);
      }
    },
    [loadOptions]
  );

  return (
    <SelectorContext.Provider
      value={{
        loadMoreLoading,
      }}
    >
      <Select
        inputId="tagSearchSelector"
        label={label}
        isDisabled={disabled}
        invalid={invalid}
        placeholder="Type to search tag name or type"
        noOptionsMessage={() => "No search results"}
        cacheOptions={false}
        backspaceRemovesValue={true}
        isClearable={false}
        isSearchable
        isLoading={loading || selectedValueLoading}
        options={loading ? [] : options}
        onChange={handleSelect}
        onInputChange={handleInputChange}
        value={selectedValue}
        menuPlacement="auto"
        components={{
          ...SelectComponents,
          MenuList,
        }}
        isValidNewOption={() => {
          return false;
        }}
        selectType="creatable"
        focus={focus}
        mandatory={mandatory}
        onMenuScrollToBottom={loadMore}
      />
    </SelectorContext.Provider>
  );
};

TagSearchSelect.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onSelectTag: PropTypes.func,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  mandatory: PropTypes.bool,
  focus: PropTypes.bool,
  tagTypes: PropTypes.array,
};

export default TagSearchSelect;
