import { useApolloClient, useQuery } from "@apollo/client";
import { debounce, filter } from "lodash";
import { useCallback, useRef, useState } from "react";

import { pagedSearchTags } from "src/queries/tags.graphql";
import { DEBOUNCE_SEARCH_TIME } from "src/consts/global";
import { TAG_OPERATIONS, TAG_SEARCH_LIMIT } from "src/consts/tags";

const usePagedTagSearch = ({
  tagTypes,
  notifyOnNetworkStatusChange = true,
  existingTags = [],
}) => {
  const client = useApolloClient();
  const existingTagsIds = filter(
    existingTags,
    (tag) => tag?.status !== TAG_OPERATIONS.REMOVED
  ).map((tag) => tag?.tagId || tag?.tag?.id);

  const [tags, setTags] = useState([]);

  const totalCountRef = useRef(0);

  const searchTermRef = useRef("");
  const currentOffset = useRef(0);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [searching, setSearching] = useState(false);

  const getFilterVariables = useCallback(
    (searchTerm) => {
      return {
        filter: {
          tagTypes,
          excludeTags: existingTagsIds,
          ...(searchTerm ? { searchTerm } : {}),
        },
        limit: TAG_SEARCH_LIMIT,
      };
    },
    [tagTypes, existingTagsIds]
  );

  const { data, error, loading } = useQuery(pagedSearchTags, {
    notifyOnNetworkStatusChange,
    variables: {
      input: {
        ...getFilterVariables(),
      },
    },
    onCompleted: (response) => {
      currentOffset.current = TAG_SEARCH_LIMIT;
      setTags(response?.results?.tags);
      totalCountRef.current = response?.results?.totalCount;
    },
  });

  const loadMore = () => {
    if (currentOffset.current < totalCountRef.current) {
      setLoadMoreLoading(true);
      client
        .query({
          query: pagedSearchTags,
          variables: {
            input: {
              ...getFilterVariables(searchTermRef.current),
              offset: currentOffset.current,
            },
          },
        })
        .then((result) => {
          setLoadMoreLoading(false);
          currentOffset.current += TAG_SEARCH_LIMIT;
          setTags([...tags, ...(result?.data?.results?.tags || [])]);
        })
        .catch((err) => {
          setLoadMoreLoading(false);
          throw err;
        });
    }
  };

  const loadOptions = debounce((value) => {
    setSearching(true);
    searchTermRef.current = value;
    client
      .query({
        query: pagedSearchTags,
        variables: {
          input: {
            ...getFilterVariables(value),
          },
        },
      })
      .then((result) => {
        currentOffset.current = TAG_SEARCH_LIMIT;
        totalCountRef.current = result?.data?.results?.totalCount;
        setTags(result?.data?.results?.tags || []);
        setSearching(false);
      })
      .catch((err) => {
        setSearching(false);
        throw err;
      });
  }, DEBOUNCE_SEARCH_TIME);

  const clearSearchTerm = () => {
    searchTermRef.current = "";
  };

  /**
   * Show loading when the tags editor is loading for the first time or when the user is searching
   * When selected tag is removed from the list or added to the list, updated variables of existing tags
   * triggers refetching of the query which makes the loading to be true
   */
  const shouldShowLoading =
    (loading && currentOffset.current === 0) || searching;

  return {
    data,
    tags,
    error,
    loading: shouldShowLoading,
    loadOptions,
    loadMore,
    loadMoreLoading,
    clearSearchTerm,
  };
};

export default usePagedTagSearch;
