import constate from "constate";
import { get } from "lodash";

export const CHALLENGE_NONE = "CHALLENGE_NONE";
export const CHALLENGE_SELECT_WORKSPACE = "CHALLENGE_SELECT_WORKSPACE";
export const CHALLENGE_SHOW_PROVISIONING_PAGE =
  "CHALLENGE_SHOW_PROVISIONING_PAGE";
export const CHALLENGE_SETUP_WORKSPACE = "CHALLENGE_SETUP_WORKSPACE";

const defaultFteConfig = {
  decimal: 2,
  currencySymbol: "$",
};

const useWorkspaceContext = (props) => {
  const {
    challenge,
    workspace,
    error,
    workspaces,
    isWorkspaceDataLoading,
    isReportingResourceProvisioned,
  } = props;
  return {
    challenge,
    workspace,
    error,
    workspaces,
    isWorkspaceDataLoading,
    isReportingResourceProvisioned,
  };
};

const [
  Provider,
  useIsWorkspaceLoading,
  useWorkspaceError,
  useWorkspaceChallenge,
  useWorkspace,
  useWorkspaces,
  useCheckReportingResourceProvisioned,

  // Deep selectors
  useGroupTypes,
  useAllocationConfig,
  useTagTypes,
  useWorkspaceTagConfig,
  useWorkspaceFeatureFlags,
  useWorkspaceFteConfig,
] = constate(
  useWorkspaceContext,
  (value) => value.isWorkspaceDataLoading,
  (value) => value.error,
  (value) => value.challenge,
  (value) => value.workspace,
  (value) => value.workspaces,
  (value) => value.isReportingResourceProvisioned,

  // Deep selectors
  (value) => get(value, "workspace.config.groupTypes"),
  (value) => get(value, "workspace.config.allocation"),
  (value) => get(value, "workspace.config.tagTypes"),
  (value) => get(value, "workspace.config.tagConfig"),
  (value) => get(value, "workspace.config.featureFlags", {}),
  (value) => get(value, "workspace.config.fteConfig", defaultFteConfig)
);

export {
  Provider,
  useIsWorkspaceLoading,
  useWorkspaceError,
  useWorkspaceChallenge,
  useWorkspace,
  useWorkspaces,
  useCheckReportingResourceProvisioned,
  useGroupTypes,
  useAllocationConfig,
  useTagTypes,
  useWorkspaceTagConfig,
  useWorkspaceFeatureFlags,
  useWorkspaceFteConfig,
};
