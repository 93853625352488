import React from "react";
import { Spacer, Typography } from "orcs-design-system";

export default () => ({
  steps: [
    {
      target: "body",
      content: (
        <Spacer my={3}>
          <Typography.H4 weight="bold" textAlign="center">
            Welcome to Filters! Here you can refine what dataset is shown in the
            Summary page.
          </Typography.H4>
          <Typography.P textAlign="center">
            Let me show you it works.
          </Typography.P>
        </Spacer>
      ),
      placement: "center",
      disableBeacon: true,
    },
    {
      target: ".guided-tour-sidebar-filter-button",
      content: (
        <Spacer my={3}>
          <Typography.H4 weight="bold" textAlign="center">
            Click the Filter button in the sidebar to access the filters panel.
          </Typography.H4>
        </Spacer>
      ),
      placement: "right",
      disableBeacon: true,
    },
    {
      target: ".guided-tour-filter-sidebar-content",
      content: (
        <Spacer my={3}>
          <Typography.H4 textAlign="left">
            This is the filters panel.
          </Typography.H4>
          <Typography.P textAlign="left">
            With no filters selected, the Summary page shows insights about the
            entire organisation.
          </Typography.P>
          <Typography.P textAlign="left">
            By applying filters, the Summary page insights narrow to specific
            individuals that match the selected criteria.
          </Typography.P>
          <Typography.P textAlign="left">
            The Summary page provides a comprehensive view of your team
            structure and composition. The dashboard displays key metrics about
            your workforce, including headcount, geographical distribution, team
            sizes, and role distribution.
          </Typography.P>
        </Spacer>
      ),
      placement: "right",
    },
    {
      target: ".guided-tour-filter-divisions",
      content: (
        <Spacer my={3}>
          <Typography.H4 textAlign="left">Analyze by Division</Typography.H4>
          <Typography.P textAlign="left">
            Filter by Division to see insights for specific business areas of
            your organisation.
          </Typography.P>
        </Spacer>
      ),
      placement: "right",
    },
    {
      target: ".guided-tour-filter-company",
      content: (
        <Spacer my={3}>
          <Typography.H4 textAlign="left">Analyze by Company</Typography.H4>
          <Typography.P textAlign="left">
            Select a Company to only see individuals that work for that entity.
          </Typography.P>
          <Typography.P textAlign="left">
            This is useful when your organisition is split into multiple
            corporate entities. It also allows you inspect an external
            organisations; such as contractors or external labour hire.
          </Typography.P>
          <Typography.Small textAlign="left">
            Try it now by selecting a Company from the list.
          </Typography.Small>
        </Spacer>
      ),
      placement: "right",
    },
    {
      target: ".guided-tour-filter-location",
      content: (
        <Spacer my={3}>
          <Typography.H4 textAlign="left">
            Analyze by geographic regions
          </Typography.H4>
          <Typography.P textAlign="left">
            You can use the Location and Country filters to narrow the dataset
            of individuals in your organisation.
          </Typography.P>
          <Typography.Small textAlign="left">
            Try it now by selecting your country from the list.
          </Typography.Small>
        </Spacer>
      ),
      placement: "right",
    },
    {
      target: ".guided-tour-filter-jobtitle",
      content: (
        <Spacer my={3}>
          <Typography.H4 textAlign="left">
            Analyze by Employee Role
          </Typography.H4>
          <Typography.P textAlign="left">
            Filter by Role to see metrics for specific job titles.
          </Typography.P>
          <Typography.Small textAlign="left">
            Try it now by selecting the top role from the list, it will be at
            the top of the list.
          </Typography.Small>
        </Spacer>
      ),
      placement: "right",
    },
    {
      target: ".guided-tour-filter-teams-types",
      content: (
        <Spacer my={3}>
          <Typography.H4 textAlign="left">Analyze by Team Types</Typography.H4>
          <Typography.P textAlign="left">
            Filter by one or more Team Types to view insights only for teams
            that match the selected Team Types.
          </Typography.P>
        </Spacer>
      ),
      placement: "right",
    },
    {
      target: ".guided-tour-filter-teams",
      content: (
        <Spacer my={3}>
          <Typography.H4 textAlign="left">Analyze by Team(s)</Typography.H4>
          <Typography.P textAlign="left">
            Select one or more specific teams in your organisation to focus on
            their metrics.
          </Typography.P>
        </Spacer>
      ),
      placement: "right",
    },
    {
      target: ".guided-tour-filter-toggle-filterIncludesSubTeams",
      content: (
        <Spacer my={3}>
          <Typography.H4 textAlign="left">
            Control if teams filter includes any sub-teams
          </Typography.H4>
          <Typography.P textAlign="left">
            If you have selected one or more teams, you can control if all the
            teams and people within your filtered team(s) are included.
          </Typography.P>
        </Spacer>
      ),
      placement: "right",
    },
    {
      target: ".guided-tour-filter-clear-btn",
      content: (
        <Spacer my={3}>
          <Typography.H4 textAlign="left">Start again</Typography.H4>
          <Typography.P textAlign="left">
            Use this button to quickly remove all filters and show the entire
            dataset again.
          </Typography.P>
          <Typography.Small textAlign="left">
            If you have been following along now is the time to clear your
            filters.
          </Typography.Small>
        </Spacer>
      ),
      placement: "right",
    },
    {
      target: ".guided-tour-filter-sidebar-content",
      placement: "right",
      content: (
        <Spacer my={3}>
          <Typography.H3 weight="bold" textAlign="center">
            That&apos;s it!
          </Typography.H3>
          <Typography.P textAlign="center">
            Try exploring more of the application by clicking some of the other
            tabs and buttons.
          </Typography.P>
          <Typography.P textAlign="center">Have fun!</Typography.P>
          <Typography.Small textAlign="left">
            We love feedback, please leave any comments or suggestions in the
            help button below.
          </Typography.Small>
        </Spacer>
      ),
    },
  ],
  autoStart: true,
  dependsOn: [],
  triggerInSidebar: true,
  showProgress: false,
  showSkipButton: true,
  hideBackButton: true,
});
