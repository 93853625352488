import { isEmpty, get, filter, some } from "lodash";
import { Flex, FlexItem } from "orcs-design-system";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useReducer,
  useRef,
} from "react";

import icons from "src/config/icons";
import ErrorNotification from "src/components/ErrorNotification";
import TagSelectorContainer from "src/components/TagsEditor/sub-components/TagSelectorContainer";

import { TAG_ATTRIBUTE_ACTION } from "src/consts/tags";
import { TagTypeConfigPropType } from "./propTypes";
import CreateNewTagModal from "./sub-components/CreateNewTagModal/CreateNewTagModal";
import ExpandableBadgeList from "./sub-components/ExpandableBadgeList";
import { Line } from "./sub-components/PlaceholderStyles";
import TagAttributesModal from "./sub-components/TagAttributesModal";
import TagDisplayValueModal from "./sub-components/TagDisplayValueModal";
import TagEditBtn from "./sub-components/TagEditBtn";
import TagTypeHelpModal from "./sub-components/TagTypeHelpModal";
import {
  withValidTagTypeConfig,
  transformTagTypeConfigs,
} from "./TagsEditor.util";

const formStateReducer = (state, action) => {
  switch (action.type) {
    case "startEditing":
      return { ...state, initialView: false, editMode: true };
    case "finishEditing":
      if (
        state.helpTagType ||
        state.attributesModalProps ||
        state.tagValueModalProps ||
        state.newTagCreationModalProps
      ) {
        return { ...state, inputTarget: action.inputTarget };
      }
      return { editMode: false };
    case "showTagTypeHelp":
      return { ...state, helpTagType: action.tagType };
    case "toggleAttributesModal":
      return {
        ...state,
        attributesModalProps: action.props,
      };
    case "toggleTagValueModal": {
      return {
        ...state,
        tagValueModalProps: action.props,
      };
    }
    case "toggleNewTagCreationModal":
      return {
        ...state,
        newTagCreationModalProps: action.props,
      };
    case "hideHelp":
      return { ...state, helpTagType: null, inputTarget: null };
    default:
      throw new Error("Unsupported action", action);
  }
};

const TagsEditor = ({
  existingTags,
  setNewTag,
  removeTag,
  onTagsLoaded,
  onFinishEdit,
  onStartEdit,
  onEntityTagClick,
  loading,
  readOnly,
  editOnly,
  isAssociationTag,
  tagTypesConfig,
  tagTypes,
  editButtonIcon,
  label,
  withAppliedCount,
  components: { TagSelector } = { TagSelector: TagSelectorContainer },
  comments,
  mt,
  bulkTagMode,
  small,
  highlighted,
  inline = false,
  entityId,
  featureFlags,
  entityType,
}) => {
  // TODO: error stuff: to be revisited
  const [error, setError] = useState(null);
  const tagSelectorRef = useRef(null);

  useEffect(() => {
    if (!error) {
      return;
    }

    const timeoutId = setTimeout(() => {
      setError(null);
    }, 5000);

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(timeoutId);
    };
  }, [error]);

  const [
    {
      editMode,
      initialView,
      helpTagType,
      inputTarget,
      attributesModalProps,
      tagValueModalProps,
      newTagCreationModalProps,
    },
    formStateDispatch,
  ] = useReducer(formStateReducer, { initialView: true, editMode: false });

  // edit state management stuff
  // todo:  better naming for the prop onFinishEdit so can name these less confusingly
  const startEditing = useCallback(() => {
    formStateDispatch({ type: "startEditing" });
  }, []);

  const finishEditing = useCallback((e) => {
    formStateDispatch({ type: "finishEditing", inputTarget: e?.target });
  }, []);

  const showTagTypeHelp = useCallback((tagType) => {
    formStateDispatch({ type: "showTagTypeHelp", tagType });
  }, []);

  const hideHelp = useCallback(() => {
    formStateDispatch({ type: "hideHelp" });
  }, []);

  useEffect(() => {
    if (inputTarget) {
      inputTarget.focus();
    }
  }, [inputTarget]);

  useEffect(() => {
    // excluding the initialView, initial value of editMode is false
    if (!editMode && !initialView && onFinishEdit) {
      onFinishEdit();
    }
  }, [editMode, initialView, onFinishEdit]);

  useEffect(() => {
    if (editMode && onStartEdit) {
      onStartEdit();
    }
  }, [editMode, onStartEdit]);

  // 'view model' naming here might not be a great idea
  const existingTagsViewModel = useMemo(() => {
    if (loading) {
      return [];
    }
    return withValidTagTypeConfig(
      existingTags,
      tagTypesConfig,
      isAssociationTag
    );
  }, [existingTags, tagTypesConfig, loading, isAssociationTag]);

  const tagTypesConfigViewModel = useMemo(() => {
    return transformTagTypeConfigs(tagTypesConfig);
  }, [tagTypesConfig]);

  const focusTagSelector = useCallback(() => {
    tagSelectorRef.current?.focus();
  }, []);

  const blurTagSelector = useCallback(() => {
    tagSelectorRef.current?.blur();
  }, []);

  const onEditTagAttributes = useCallback(
    (tag, tagTypeConfig) => {
      formStateDispatch({
        type: "toggleAttributesModal",
        props: {
          entityTag: tag,
          tagTypeConfig,
          comments,
        },
      });
      blurTagSelector();
    },
    [blurTagSelector, comments]
  );

  const onCloseTagAttributes = useCallback(() => {
    formStateDispatch({
      type: "toggleAttributesModal",
      props: null,
    });
    focusTagSelector();
  }, [focusTagSelector]);

  const onCloseTagValueModal = useCallback(() => {
    formStateDispatch({
      type: "toggleTagValueModal",
      props: null,
    });
    focusTagSelector();
  }, [focusTagSelector]);

  const onCloseNewTagCreationModal = useCallback(() => {
    formStateDispatch({
      type: "toggleNewTagCreationModal",
      props: null,
    });
    focusTagSelector();
  }, [focusTagSelector]);

  const createNewEntityTagIfTagHasMandatoryUniqueAttributes = useCallback(
    (fn) => async (tag, tagTypeConfig) => {
      if (
        some(tagTypeConfig?.attributes?.entityTag, {
          action: TAG_ATTRIBUTE_ACTION.MANDATORY,
        })
      ) {
        formStateDispatch({
          type: "toggleNewTagCreationModal",
          props: {
            allTags: transformTagTypeConfigs(
              filter(tagTypesConfig, "isEditableByUsers")
            ),
            setNewTag,
            initialDisplayValue: tag?.displayValue || "",
            initialTagType: tagTypeConfig,
            initialAttributes: tag?.attributes,
          },
        });
      } else {
        await fn(tag, tagTypeConfig);
      }
    },
    [formStateDispatch, tagTypesConfig, setNewTag]
  );

  const onAddNewTag = useCallback(
    async (tag, tagTypeConfig) => {
      const { isUserCreatedTagValue } = tag;

      if (isUserCreatedTagValue) {
        const values = get(tagTypeConfig, "attributes.values");

        if (!isEmpty(values)) {
          formStateDispatch({
            type: "toggleAttributesModal",
            props: {
              tag,
              tagTypeConfig,
              isNewTag: true,
              setNewTag,
            },
          });
        } else {
          formStateDispatch({
            type: "toggleTagValueModal",
            props: {
              tag,
              tagTypeConfig,
              setNewTag,
            },
          });
        }
        blurTagSelector();
      } else {
        await setNewTag(tag, tagTypeConfig);
      }
    },
    [setNewTag, blurTagSelector]
  );

  const onRemoveTag = useCallback(
    async (tag, tagTypeConfig) => {
      await removeTag(tag, tagTypeConfig);
    },
    [removeTag]
  );

  const onCreateNewTag = () => {
    formStateDispatch({
      type: "toggleNewTagCreationModal",
      props: {
        allTags: transformTagTypeConfigs(
          filter(tagTypesConfig, "isEditableByUsers")
        ),
        setNewTag,
      },
    });
  };

  const isTagsEmpty = isEmpty(existingTagsViewModel);
  // todo : cut down on number of bools to check

  const noContent = isTagsEmpty && !editMode;

  const flexStack = noContent ? "0 0 auto" : "1 1 100%";
  const addMargin = noContent ? "0" : "s";

  if (inline) {
    return (
      <>
        <ExpandableBadgeList
          isEditMode={editMode}
          tags={existingTagsViewModel}
          tagTypesConfig={tagTypesConfigViewModel}
          onEntityTagClick={onEntityTagClick}
          withAppliedCount={withAppliedCount}
          small={small}
          highlighted={highlighted}
          inline={inline}
          onClickTagInfo={onEditTagAttributes}
          entityType={entityType}
        />
        {attributesModalProps && (
          <TagAttributesModal
            {...attributesModalProps}
            onClose={onCloseTagAttributes}
            entityId={entityId}
            tagTypes={tagTypes}
            featureFlags={featureFlags}
            editMode={editMode || editOnly}
          />
        )}
      </>
    );
  }

  return (
    <FlexItem flex={flexStack} mt={mt || addMargin}>
      <Flex
        alignItems="flex-start"
        justifyContent="space-between"
        flexDirection={noContent ? "row" : "column"}
      >
        {helpTagType && (
          <TagTypeHelpModal tagType={helpTagType} onClose={hideHelp} />
        )}
        {loading && (
          <Line width={200} height={24} role="alert" aria-busy={true} />
        )}
        {!loading && (editMode || editOnly) && (
          <TagSelector
            selectorRef={tagSelectorRef}
            loading={loading}
            existingTags={existingTagsViewModel}
            onError={setError}
            setNewTag={createNewEntityTagIfTagHasMandatoryUniqueAttributes(
              onAddNewTag
            )}
            removeTag={onRemoveTag}
            onTagsLoaded={onTagsLoaded}
            onBlur={finishEditing}
            onEntityTagClick={onEntityTagClick}
            tagTypesConfig={tagTypesConfigViewModel}
            tagTypes={tagTypes}
            showTagTypeHelp={showTagTypeHelp}
            helpIsOpen={!!helpTagType}
            isAssociationTag={isAssociationTag}
            onEditTagAttributes={onEditTagAttributes}
            onCreateNewTag={onCreateNewTag}
            label={label}
            bulkTagMode={bulkTagMode}
            entityType={entityType}
          />
        )}
        {!loading && !isTagsEmpty && !editMode && !editOnly && (
          <ExpandableBadgeList
            isEditMode={editMode}
            tags={existingTagsViewModel}
            tagTypesConfig={tagTypesConfigViewModel}
            onEntityTagClick={onEntityTagClick}
            withAppliedCount={withAppliedCount}
            small={small}
            onClickTagInfo={onEditTagAttributes}
            entityType={entityType}
          />
        )}
        {!loading && !readOnly && !editOnly && (
          <FlexItem flex="0 0 auto" mt={addMargin}>
            <TagEditBtn
              editMode={editMode}
              onEditClicked={startEditing}
              onFinishEditClicked={finishEditing}
              isTagsEmpty={isTagsEmpty}
              icon={editButtonIcon || icons.pen}
              isAssociationTag={isAssociationTag}
            />
          </FlexItem>
        )}
        {error && <ErrorNotification message={error?.message} error={error} />}
      </Flex>
      {attributesModalProps && (
        <TagAttributesModal
          {...attributesModalProps}
          onClose={onCloseTagAttributes}
          entityId={entityId}
          tagTypes={tagTypes}
          featureFlags={featureFlags}
          tagTypesConfig={tagTypesConfig}
          editMode={editMode || editOnly}
        />
      )}
      {tagValueModalProps && (
        <TagDisplayValueModal
          {...tagValueModalProps}
          onClose={onCloseTagValueModal}
        />
      )}
      {newTagCreationModalProps && (
        <CreateNewTagModal
          {...newTagCreationModalProps}
          onClose={onCloseNewTagCreationModal}
          featureFlags={featureFlags}
          tagTypesConfig={tagTypesConfig}
        />
      )}
    </FlexItem>
  );
};

TagsEditor.propTypes = {
  existingTags: PropTypes.array,
  setNewTag: PropTypes.func.isRequired,
  removeTag: PropTypes.func.isRequired,
  onStartEdit: PropTypes.func,
  onFinishEdit: PropTypes.func,
  onEntityTagClick: PropTypes.func,
  loading: PropTypes.bool,
  onTagsLoaded: PropTypes.func,
  readOnly: PropTypes.bool,
  editOnly: PropTypes.bool,
  isAssociationTag: PropTypes.bool,
  mt: PropTypes.string,
  label: PropTypes.string,
  editButtonIcon: PropTypes.array,
  tagTypesConfig: PropTypes.arrayOf(TagTypeConfigPropType),
  tagTypes: PropTypes.arrayOf(PropTypes.string),
  withAppliedCount: PropTypes.bool,
  components: PropTypes.shape({ TagSelector: PropTypes.elementType.isRequired })
    .isRequired,
  comments: PropTypes.object,
  //  tagTypesConfig: PropTypes.arrayOf(TagsEditorPropTypes.tagTypeConfig).isRequired,
  bulkTagMode: PropTypes.bool,
  small: PropTypes.bool,
  inline: PropTypes.bool,
  highlighted: PropTypes.bool,
  entityId: PropTypes.string,
  featureFlags: PropTypes.object,
  entityType: PropTypes.string,
};

export default TagsEditor;
