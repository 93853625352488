/* istanbul ignore file */

export const FILTER_CONFIGS = {
  COMPANY: {
    name: "company",
    valueKey: "company",
    optionsKey: "companies",
    isMulti: true,
    isRemoteSearch: true,
    inputId: "filterCompany",
    label: "Company",
  },
  LOCATION: {
    name: "location",
    valueKey: "location",
    optionsKey: "locations",
    isMulti: true,
    isRemoteSearch: true,
    inputId: "filterLocation",
    label: "Location",
  },
  COUNTRY: {
    name: "country",
    valueKey: "country",
    optionsKey: "countries",
    isMulti: true,
    isRemoteSearch: false,
    inputId: "filterCountry",
    label: "Country",
  },
  JOBS: {
    name: "jobTitle",
    valueKey: "jobTitle",
    optionsKey: "jobs",
    isMulti: true,
    isRemoteSearch: true,
    inputId: "filterJob",
    label: "Role",
  },
  TEAM_TYPES: {
    name: "teams.types",
    valueKey: "teams.types",
    optionsKey: "teamTypes",
    isMulti: true,
    isRemoteSearch: false,
    inputId: "filterTeamType",
    label: "Team Type",
  },
  TEAMS: {
    name: "teams",
    valueKey: "teams.teams",
    isMulti: true,
    isRemoteSearch: true,
    inputId: "filterTeam",
    label: "Team",
    toggles: [
      {
        id: "filterIncludesSubTeams",
        label: "Include sub-teams",
        valueKey: "teams.includeSubTeams",
      },
    ],
  },
};
