import { Button, Flex, FlexItem, Loading, Small } from "orcs-design-system";
import React from "react";
import { components } from "react-select";
import styled from "styled-components";
import PropTypes from "prop-types";
import { SelectorContext } from "../contexts/selectorContext";

const StyledContainer = styled.div`
  background: white;
  &:hover {
    background: #d2eeff;
  }
`;

const MultiValueRemove = () => {
  return null;
};

export const CustomMenuList = ({ children, ...rest }) => {
  const { createNewTag, loadMoreLoading } = React.useContext(SelectorContext);
  return (
    <>
      <StyledContainer onClick={() => createNewTag()}>
        <Flex
          style={{ padding: "10px" }}
          justifyContent="space-between"
          alignItems="center"
        >
          <FlexItem flex="1 1 auto" mr="s">
            <Small>{`If desired tag does not already exist, you can create a
            new tag`}</Small>
          </FlexItem>
          <FlexItem flex="0 0 auto" ml="s">
            <Button onClick={() => createNewTag()} small variant="success">
              Create new tag
            </Button>
          </FlexItem>
        </Flex>
      </StyledContainer>
      <components.MenuList {...rest}>
        {children}
        {loadMoreLoading && <Loading centered />}
      </components.MenuList>
    </>
  );
};

CustomMenuList.propTypes = {
  children: PropTypes.node,
};

export default { MultiValueRemove };
