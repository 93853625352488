import { isNumber, isFinite } from "lodash";

const defaultOption = { maximumFractionDigits: 2 };

export const setDefaultDecimalPlaces = (decimalplaces) => {
  if (!isFinite(decimalplaces)) {
    return;
  }

  defaultOption.maximumFractionDigits = decimalplaces;
};

const getOption = (decimalplaces) => {
  if (!isFinite(decimalplaces)) {
    return defaultOption;
  }

  return { maximumFractionDigits: decimalplaces };
};

export const numberToLocaleString = (
  value,
  { decimalplaces, defaultValue = null } = {}
) => {
  const lang = navigator.language || "en-AU";

  return isNumber(value)
    ? value.toLocaleString(lang, getOption(decimalplaces))
    : defaultValue;
};
