import { uniqBy, isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

import usePagedTagSearch from "src/hooks/usePagedTagSearch";
import { TagTypeConfigPropType } from "../propTypes";
import { filterTagTypes } from "../utils/filterTagTypes";

import TagSelector from "./TagSelector";

const TagSelectorContainer = ({
  existingTags,
  setNewTag,
  tagTypesConfig,
  tagTypes,
  removeTag,
  onTagsLoaded,
  onBlur,
  onError,
  onEntityTagClick,
  showTagTypeHelp,
  helpIsOpen,
  isAssociationTag,
  onEditTagAttributes,
  selectorRef,
  onCreateNewTag,
  label,
  bulkTagMode,
  entityType,
}) => {
  const {
    tags,
    loadMore,
    loadOptions,
    loading,
    loadMoreLoading,
    error,
    clearSearchTerm,
  } = usePagedTagSearch({
    existingTags,
    tagTypes: filterTagTypes({ tagTypesConfig, tagTypes }),
    notifyOnNetworkStatusChange: false,
  });

  const allTags = uniqBy(tags, (tag) => `${tag.type}.${tag.displayValue}`);

  useEffect(() => {
    // todo: Revisit error handling here
    if (error && onError) {
      onError(error);
    }
  }, [onError, error]);

  useEffect(() => {
    if (!loading && !error && !isEmpty(allTags) && onTagsLoaded) {
      onTagsLoaded(allTags);
    }
  }, [loading, error, allTags, onTagsLoaded]);

  return (
    <TagSelector
      selectorRef={selectorRef}
      tagTypes={tagTypes}
      loading={loading}
      existingTags={existingTags}
      setNewTag={setNewTag}
      tagTypesConfig={tagTypesConfig}
      removeTag={removeTag}
      setError={onError}
      onBlur={onBlur}
      onEntityTagClick={onEntityTagClick}
      allTags={allTags}
      showTagTypeHelp={showTagTypeHelp}
      helpIsOpen={helpIsOpen}
      isAssociationTag={isAssociationTag}
      onEditTagAttributes={onEditTagAttributes}
      onCreateNewTag={onCreateNewTag}
      label={label}
      bulkTagMode={bulkTagMode}
      entityType={entityType}
      loadOptions={loadOptions}
      loadMore={loadMore}
      loadMoreLoading={loadMoreLoading}
      clearSearchTerm={clearSearchTerm}
    />
  );
};

TagSelectorContainer.propTypes = {
  existingTags: PropTypes.array,
  setNewTag: PropTypes.func,
  tagTypesConfig: PropTypes.arrayOf(TagTypeConfigPropType).isRequired,
  tagTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  removeTag: PropTypes.func,
  onBlur: PropTypes.func,
  onError: PropTypes.func,
  onTagsLoaded: PropTypes.func,
  onEntityTagClick: PropTypes.func,
  label: PropTypes.string,
  showTagTypeHelp: PropTypes.func,
  helpIsOpen: PropTypes.bool,
  isAssociationTag: PropTypes.bool,
  onEditTagAttributes: PropTypes.func,
  selectorRef: PropTypes.object,
  onCreateNewTag: PropTypes.func,
  bulkTagMode: PropTypes.func,
  entityType: PropTypes.string,
};

export default TagSelectorContainer;
