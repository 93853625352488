import moment from "moment";

import { FORECAST_SUBMIT_PENDING_MSG } from "src/allocation/consts";
import { WARN } from "../../../notificationStatus";
import newNotification from "./newNotification";

export default (macroAllocation, rootGroup, isActive) => {
  const {
    requested,
    requestSubmitted,
    requestSavedAt,
    requestSubmittedAt,
    forecastedAt,
  } = macroAllocation;
  // If the group haven't submitted forecast before, treat every saved forecasts as changed
  const { enableForecastSubmitting, forecastSubmittedAt, isDemand } = rootGroup;

  if (
    enableForecastSubmitting &&
    isDemand &&
    !isActive &&
    forecastedAt &&
    forecastedAt > forecastSubmittedAt
  ) {
    const forecastedAtMoment = moment(forecastedAt);

    return newNotification({
      status: WARN,
      message: `Forecast edited ${forecastedAtMoment.fromNow()}, unsubmitted`,
      columnMessage: FORECAST_SUBMIT_PENDING_MSG,
      groupMessage: FORECAST_SUBMIT_PENDING_MSG,
      isGroupNotificationDisplayedOnRoot: false,
    });
  }

  // requested saved value is different from submitted
  if (requestSavedAt) {
    if (
      !requestSubmittedAt ||
      (requestSubmittedAt && requested !== requestSubmitted)
    ) {
      return newNotification({
        status: WARN,
        columnMessage:
          "Values in this column reflect submitted values from planner. Saved values are not included.",
        isGroupNotificationDisplayedOnRoot: false,
      });
    }
  }

  return newNotification({
    status: null,
    message: null,
    groupMessage: null,
    columnMessage: null,
  });
};
