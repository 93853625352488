/* eslint-disable import/prefer-default-export */
import PropTypes from "prop-types";
import React from "react";
import { Loading } from "orcs-design-system";
import { components } from "react-select";
import { SelectorContext } from "../../TagsEditor/contexts/selectorContext";

export const MenuList = ({ children, ...rest }) => {
  const { loadMoreLoading } = React.useContext(SelectorContext);
  return (
    <components.MenuList {...rest}>
      {children}
      {loadMoreLoading && <Loading centered />}
    </components.MenuList>
  );
};

MenuList.propTypes = {
  children: PropTypes.elementType,
};
