import { some, values } from "lodash";
import { FORECAST_SUBMIT_PENDING_MSG } from "src/allocation/consts";

const checkForPending = (macroAllocations, isRequestor) => {
  if (isRequestor) {
    return some(macroAllocations, (ma) => ma.requestStatus === "SAVED");
  }
  return some(macroAllocations, (ma) => ma.approvalStatus === "SAVED");
};

const checkForForecastPending = (groupingLookup) => {
  return some(values(groupingLookup), (g) =>
    some(
      values(g.notifications),
      (n) => n.notification?.groupMessage === FORECAST_SUBMIT_PENDING_MSG
    )
  );
};

export { checkForPending, checkForForecastPending };
